exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-area-we-service-landscaping-belconnen-tsx": () => import("./../../../src/pages/area-we-service/landscaping-belconnen.tsx" /* webpackChunkName: "component---src-pages-area-we-service-landscaping-belconnen-tsx" */),
  "component---src-pages-area-we-service-landscaping-gungahlin-tsx": () => import("./../../../src/pages/area-we-service/landscaping-gungahlin.tsx" /* webpackChunkName: "component---src-pages-area-we-service-landscaping-gungahlin-tsx" */),
  "component---src-pages-area-we-service-landscaping-queanbeyan-tsx": () => import("./../../../src/pages/area-we-service/landscaping-queanbeyan.tsx" /* webpackChunkName: "component---src-pages-area-we-service-landscaping-queanbeyan-tsx" */),
  "component---src-pages-area-we-service-landscaping-woden-tsx": () => import("./../../../src/pages/area-we-service/landscaping-woden.tsx" /* webpackChunkName: "component---src-pages-area-we-service-landscaping-woden-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-residential-tsx": () => import("./../../../src/pages/residential.tsx" /* webpackChunkName: "component---src-pages-residential-tsx" */),
  "component---src-pages-schools-and-playgrounds-tsx": () => import("./../../../src/pages/schools-and-playgrounds.tsx" /* webpackChunkName: "component---src-pages-schools-and-playgrounds-tsx" */),
  "component---src-pages-services-gardening-and-mulching-tsx": () => import("./../../../src/pages/services/gardening-and-mulching.tsx" /* webpackChunkName: "component---src-pages-services-gardening-and-mulching-tsx" */),
  "component---src-pages-services-lawns-and-turfing-tsx": () => import("./../../../src/pages/services/lawns-and-turfing.tsx" /* webpackChunkName: "component---src-pages-services-lawns-and-turfing-tsx" */),
  "component---src-pages-services-paving-and-concreting-tsx": () => import("./../../../src/pages/services/paving-and-concreting.tsx" /* webpackChunkName: "component---src-pages-services-paving-and-concreting-tsx" */),
  "component---src-pages-services-retaining-walls-tsx": () => import("./../../../src/pages/services/retaining-walls.tsx" /* webpackChunkName: "component---src-pages-services-retaining-walls-tsx" */)
}

